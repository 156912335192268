import React, {useState} from 'react';

import routes from 'config/routes';
import {NATIVE_EVENTS, NATIVE_MESSAGES} from 'constants/native-events';
import {useTranslations} from 'hooks/use-translations';
import {useNativeListener} from 'hooks/use-native-listener';
import useNavigation from 'hooks/app/use-navigation';
import {logout} from 'services/account';
import {sendMessageToNativeApp} from 'services/native-api';

import DialogBox from 'components/DialogBox/DialogBox';

interface LogoutDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutDialogBox = (props: LogoutDialogBoxProps) => {
  const {isOpen, onClose} = props;
  const {translate} = useTranslations();
  const {handleGoBack} = useNavigation({
    replaceLocation:
    routes.SETTINGS.href,
  });

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogoutConfirmation = async (shouldLogout: boolean) => {
    if (!shouldLogout) {
      onClose();
      return;
    }

    try {
      setIsLoggingOut(true);
      await logout();
      const message = {
        type: NATIVE_MESSAGES.REQUEST_NEW_TOKEN,
      };
      sendMessageToNativeApp({message});
    } catch (e: any) {
      setIsLoggingOut(false);
    }
  };

  const handleNewToken = () => {
    handleGoBack();
  };

  useNativeListener({
    event: NATIVE_EVENTS.NEW_TOKEN,
    callback: handleNewToken,
  });

  return (
    <React.Fragment>
      {isOpen && (
        <DialogBox
          title={translate('sdk.web.account.logout.title')}
          promptMessage={translate('sdk.web.account.logout.prompt')}
          onConfirmation={handleLogoutConfirmation}
          yesText={translate('sdk.web.dialog.box.confirm')}
          noText={translate('sdk.web.dialog.box.cancel')}
          isLoading={isLoggingOut}
        />
      )}
    </React.Fragment>
  );
};

export default LogoutDialogBox;
